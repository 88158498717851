import React from 'react';
import { Layout } from '@/components/Layout';
import { HeroText } from '@/components/HeroText';
import { projects } from '@/cms/projects';
import { ProjectTout } from '@/components/ProjectTout';
import { Box } from 'rebass/styled-components';
import { Desktop } from '@/components/Desktop';

export default () => (
  <Layout>
    <HeroText
      text={projects.heroText}
      fontSize={[5, 6]}
      mt={[3, 3, 0]}
      pb={['90px', '90px', '170px']}
    />
    <Box mb={[0, 0, 5]}>
      {projects.items.map((p) => (
        <ProjectTout {...p} isHome={false} />
      ))}
      <Desktop>
        <hr style={{ marginBottom: 0 }} />
      </Desktop>
    </Box>
  </Layout>
);
