import * as React from 'react';
import { Heading } from 'rebass/styled-components';
import { styledTheme } from '@/styledTheme';
import { ContentWrapper } from './ContentWrapper';

export const HeroText = ({ text, fontSize = [6], ...boxProps }) => (
  <ContentWrapper maxWidth={1000} pt={[4, 4, 5]} pb={['170px']} {...boxProps}>
    <Heading
      fontSize={fontSize}
      fontWeight={700}
      maxWidth={750}
      fontFamily={styledTheme.fonts.body}
    >
      {text}
    </Heading>
  </ContentWrapper>
);
